@import '~antd/dist/antd.less';
@import './style/reset.less';
@import './style/global.less';
// less公共变量
@import './style/common.less';

// 自定义样式
@import './style/customize.less';


.skyeye_pc_app {
  .common_width {
    width: @main-width;
  }
  .common_width-fit {
    width: @main-width-fit;
  }
  .common_width_margin {
    .common_width;
    margin: 0 auto;
  }
  .common_width_margin_fit {
    .common_width-fit;
    margin: 0 auto;
  }
  &__content {
    min-height: calc(100vh - @header_nav_height - @footer_copyright_height);
    background: #F5F6F9;
    padding-top: 20px;
    padding-bottom: 60px;
  }
}

@primary-color: #ff6600;