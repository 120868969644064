.skyeye_pc_home {
    &__tabs {
        margin-bottom: 10px;
        padding: 0 20px;
        span {
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            line-height: 20px;
            margin-right: 20px;
            cursor: pointer;
        }
        &--active {
            font-size: 16px !important;
            color: #FF6600 !important;
            line-height: 22px !important;
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
    }
}
@primary-color: #ff6600;