.skyeye_pc_operational_data {
  //页面标题
  &_page_title {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    &_label {
      font-size: 16px;
      
      font-weight: 500;
      color: #FF6600;
      line-height: 22px;
      margin-right: 20px;
    }

    &_update_time {
      font-size: 12px;
      
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }

  //折线图部分
  &__line_charts {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    padding: 20px;
    margin-bottom: 10px;

    &__tags {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      cursor: pointer;

      &__tag {
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        padding: 20px 20px 12px;
        height: 145px;
        flex: 1;
        &:not(:last-child) {
          margin-right: 10px;
        }

        &_title {
          font-size: 16px;
          
          font-weight: 400;
          color: #999999;
          line-height: 16px;
          display: flex;
          margin-bottom: 16px;
          >img {
            margin-left: 8px;
          }
          // 气泡样式
          &--tooltip {
            font-size: 12px !important;
            >.ant-tooltip-content {
              >.ant-tooltip-arrow {
                >.ant-tooltip-arrow-content {
                  width: 10px;
                  height: 10px;
                  background-color: rgba(0, 0, 0, 0.8);
                  border-radius: 0 0 2px 0;
                }
              }
              >.ant-tooltip-inner {
                padding: 8px 12px;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.8);
              }
            }
          }
        }

        &_main_data {
          font-size: 30px;
          
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-end;

          .main_data_label {
            font-size: 14px;
            
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            margin-left: 20px;
            position: relative;

            &_title {
              font-size: 14px;
              
              line-height: 22px;
              margin-left: 8px;

              &_red {
                color: #F53731;
              }

              &_green {
                color: #6BE653;
              }
            }

            &_triangle_red {
              border-bottom: 5px solid #F53731;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              position: absolute;
              margin-left: 4px;
              bottom: 40%;
            }

            &_triangle_green {
              border-top: 5px solid #6BE653;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              position: absolute;
              margin-left: 4px;
              top: 40%;
            }
          }
        }

        &_bottom {
          .range_picker{
            padding-top: 10px;
            border-top: 1px solid rgba(255, 102, 0, 0.1);
            display: flex;
            justify-content: space-between;
            min-width: 192.5px;

            span {
              font-size: 14px;
              
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              cursor: pointer;
            }

            .checked {
              font-size: 14px;
              
              font-weight: 400;
              color: #FF6600;
              line-height: 20px;
              cursor: pointer;
            }
          }

          .date {
            font-size: 12px;
            
            font-weight: 400;
            color: #FF6600;
            line-height: 22px;
            padding-top: 10px;
          }
        }
      }

      &__tag_checked {
        background: #FFF6F0;
        border-radius: 4px;
        border: 1px solid #FF6600;
      }
    }

    &_chart {
      &__title {
        font-size: 14px;
        
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
    }
  }

  //客户列表部分
  &__customer_list {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 20px;

    &_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-bottom: 10px;
      &--input {
        width: 224px;
        >.ant-input-affix-wrapper {
          padding-left: 7px;
          padding-right: 8px;
        }
      }

      input {
        width: 224px;
      }
    }
    &_table {

      > .ant-table-wrapper {
         .ant-table-thead {
          background: url('./img/customer_list.png');
          background-size: 100%;

          > tr > th {
            background: unset;
            color: #FFFFFF;
          }
        }
      }

      .ant-table-pagination.ant-pagination {
        margin: 14px 0 0 0;
        >.ant-pagination-item-active {
          background: #ff6600;
          >a {
            color: #fff;
          }
        }
      }
    }
  }
}
@primary-color: #ff6600;