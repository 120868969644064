.project_list_iteration_details {
    min-height: 166px;
    &__title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }
    &__list, &__list02 {
        display: flex;
        flex-wrap: wrap;
        padding: 14px 0 10px;
        >li {
            width: calc(100% / 3);
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            padding-left: 40px;

            >div, >span {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
            }

        }

        .color_and_underline() {
            color: #FF6600 !important;
            text-decoration: underline;
            cursor: pointer;
        }

        &--underline {
            .color_and_underline();
        }

        &--arrow {
            .color_and_underline();
            display: flex;
            align-items: center;
            >div {
                padding-left: 8px;
                display: flex;
                align-items: center;
            }
        }
    }

    &__list02 {
        padding-bottom: 0;
        >li {
            width: calc(100% / 5);
            padding-left: 35px;
        }
    }
}
@primary-color: #ff6600;