// less公共变量 / 方法
@import '~@style/common.less';

// logo宽度
@logo_width: 160px;

.skyeye_pc_header {
    width: 100%;
    background: #fff;
    height: @header_nav_height;
    line-height: @header_nav_height;
    overflow: hidden;
    box-shadow: 0px 4px 17px 0px rgba(228, 228, 228, 0.5);
    position: relative;
    z-index: 999;
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        &--logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            left: -(@logo_width + 10);
            >a {
                display: inline-block;
            }
        }
        &--nav {
            width: 100%;
            >a {
                font-size: 16px;
                margin: 0 20px;
                color: #666;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                &::after {
                    content: '';
                    width: 100%;
                    height: 3px;
                    background: transparent;
                    border-radius: 2px;
                    position: absolute;
                    bottom: 0;
                }
            }
            &__active {
                color: #FF6600 !important;
                font-weight: 500 !important;
                &::after {
                    background: #FF6600 !important;
                }
            }
        }
        &--user {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            position: absolute;
            >span {
                display: flex;
                align-items: center;
            }
            &__Badge {
                top: auto !important;
                right: auto !important;
                left: 18px;
                bottom: -4px;
            }
            .ant-badge {
                min-width: 70px;
                height: 100%;
                cursor: pointer;
            }
            &__name {
                font-size: 15px;
                font-weight: 500;
                color: #333333;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-left: -10px;
                padding-right: 30px;
                >div {
                    max-width: 60px;
                    // 单行省略 - less方法
                    .single_line_ellipsis();
                }
                >span {
                    margin-left: 12px;
                }
            }
            &__avatar {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                width: 200px;
                min-height: 193px;
                padding: 24px 50px 20px;
                >.ant-image {
                    border-radius: 50%;
                    overflow: hidden;
                    height: 100px;
                    .ant-image-img {
                        height: 100%;
                    }
                }
                &--Upload {
                    cursor: pointer;
                }
            }
            &__info {
                display: flex;
                flex-direction: column;
                width: 200px;
                height: 193px;
                padding: 16px 16px 20px;
                >.ant-image {
                    border-radius: 50%;
                    overflow: hidden;
                    height: 114px;
                    .ant-image-img {
                        height: 100%;
                    }
                }
                >img {
                    margin-top: 27px;
                    cursor: pointer;
                }
                &--uInfo {
                    width: 100%;
                    border-bottom: 1px solid #EEEFF3;
                    padding: 0px 0px 12px;
                    div {
                        &:first-child {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 12px;
                            span, div {
                                color: #333;
                            }
                            span {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 19px;
                            }
                            div {
                                font-size: 13px;
                                font-weight: normal;
                                line-height: 18px;
                            }
                        }
                        &:last-child {
                            span, div {
                                color: #333;
                            }
                            span {
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 18px;
                                margin-bottom: 4px;
                                display: block;
                            }
                            div {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17px;
                            }
                        }
                    }
                }
                &--function {
                    padding: 0;
                    >a, >div {
                        height: 22px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        margin-top: 16px;
                        >img {
                            margin-right: 11px;
                        }
                        >div {
                            font-size: 13px;
                            font-weight: 500;
                            color: #333333;
                            line-height: 18px;
                        }
                        >svg {
                            margin-left: auto;
                            color: #C9C9C9;
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #ff6600;