// 全局样式
.ant-popover {
	.ant-popover-inner-content {
		padding: 0;
	}
}
.ant-steps-item-disabled {
	cursor: text;
}

// antd气泡卡片Popover - 相关样式
.ant-popover {
	>.ant-popover-content {
		// 顶部小箭头
		>.ant-popover-arrow {
			width: 20px;
			height: 20px;
			border-width: 10px;
			top: 2px;
			right: 28px;
		}
	}
}
// 修改Chrome浏览器中自带input样式input:-internal-autofill-selected
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
}

body {
	>iframe {
		display: none;
	}
}
