// 搜索组合
.search-group-contain{
background-color: #fff;
padding: 20px;
}


// 部门员工数据
.employee-data-contain{
  padding: 20px;
  background-color: #fff;
  margin-top: 10px;

  .title-data-contain{
    .alert{
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 14px;
    }
    .data{
    display: flex;
    flex-direction: row;
    .item-content{
    flex: 1;
    width: 176px;
    height: 102px;
    margin-right: 10px;
    background-position: center;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 20px;
    justify-content: space-between;
    .item-words{
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
    .item-words > span:last-child{
      margin-left: 8px;
    }
    .item-data{
      color: #fff;
      font-size: 30px;
      font-weight: 500;
    }
    .item-data>span:last-child{
      font-size: 16px;
      font-weight: 400;
    }
    }
    .item-content:last-child{
       margin-right: 0px;
    }
    .item-content > div{
      //  padding-left: 20px;
    }
    .item-content > div:first-child{
      // flex: 2;
      display: flex;
      align-items: center;
    }
    .item-content > div:last-child{
      // flex: 2;
    }
    .usual-img{
      background-image: url(../../img/usual.png);
      background-size: 100%;
    }
    .unusual-img{
      background-image: url(../../img/unusual.png);
      background-size: 100%;
    }
    .waste-img{
      background-image: url(../../img/waste.png);
      background-size: 100%;
    }
    .flirt-img{
      background-image: url(../../img/flirt.png);
      background-size: 100%;
    }
    .work-overtime-img{
      background-image: url(../../img/work-overtime.png);
      background-size: 100%;
    }
   
  }
  }

  .list-data-contain{
    margin-top: 10px;
    .ant-checkbox-group-item{
      margin-right: 20px;
    }
    .ant-checkbox + span{
      padding-right: 0px;
    }
    .tabs-btn{
      margin-bottom: 16px;
      text-align: right;
          >.ant-radio-group {
            >.ant-radio-button-wrapper {
              height: 28px;
              width: 80px;
              text-align: center;
              line-height: 26px;
              padding: 0 10px;
              &:not(.ant-radio-button-wrapper-checked) {
                color: #FF6600;
                border-color: #FF6600;
              }
            }
          }
    }
    .ant-table-thead{
      height: 70px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child{
      border:none;
      border-right: 1px solid #fff;
    }
     .ant-table-thead > tr > th{
      padding-bottom: 6px;
      padding-top: 6px;
     }

    .ant-table-thead > tr > th:not(:first-child){
      text-align: center !important;
    }

     .ant-table-tbody > tr > td:not(:first-child) {
       padding-left: 40px;
     }


     .ant-table-thead > tr:last-child > th{
       font-weight: 400;
     }

    //  .ant-table-thead 
     // 点击数据列表后相关样式的调整
     .click-row-bgc{
      //  background-color: #fff4e6;
      border-top:  1px solid;
      border-bottom: 1px solid ;
      border-image: linear-gradient(90deg, rgba(255, 132, 0, 1), rgba(255, 77, 0, 1)) 1 1;
     }
    .click-row-bgc >td {
      border-top:  1px solid ;
      border-bottom: 1px solid ;
      border-image: linear-gradient(90deg, rgba(255, 132, 0, 1), rgba(255, 77, 0, 1)) 1 1;
    }
    .click-row-bgc>td:first-child{
      border-left:  1px solid ;
       border-image: linear-gradient(90deg, rgba(255, 132, 0, 1), rgba(255, 77, 0, 1)) 1 1;
    }
     .click-row-bgc>td:last-child{
      border-right:  1px solid ;
       border-image: linear-gradient(90deg, rgba(255, 132, 0, 1), rgba(255, 77, 0, 1)) 1 1;
    }
    .ant-table-tbody{
     > .click-row-bgc:hover >td{
    //  background:#fff4e6!important;
     }
    } 

    // .ant-table-tbody{
    //  > tr:hover>td{
    //  background: pink;
    // //  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    //  }
    // }

// 列表头部当前日期改造
.ant-table-thead{
  > tr :first-child > th :last-child{
    padding: 0px 0px;
    border: none;
  }
}
.ant-table-thead{
  > tr:last-child > th{
    border-top: none;
    // border-left: 1px solid #f0f0f0;
  }
}

    // 岗位右侧阴影
    .employee-data-position-shadow{
       box-shadow:  inset 10px 0 8px -8px rgba(200, 201, 204, 0.5);
       padding: 0px 0px !important;
    }

    .employee-data-position-shadow>div:first-child{
      padding-left: 6px !important;
    }


     .alert{
       display: flex;
       flex-direction: row;
       align-items: center;
       padding-top: 15px;
       >img{
         width: 16px;
         height: 16px;
       }
       >span{
         color: rgba(0, 0, 0, 0.4);
         font-weight: 400;
         font-size: 12px;
         margin-left: 6px;
       }
     }
     .day-describe{
       width: 100%;
       height: 24px;
       border-radius: 12px;
       text-align: center;
     }


     .list-status-box{
       text-align: center;
      display: inline-block;
      font-size: 12px;
      text-align: center;
      line-height: 24px;
      .status{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 54px;
      }
     }
     .usual{
      background: rgba(49, 210, 87, 0.2);
        color: #0EC75A;
     }
      // 有计划
    .have-plan{
      color: #0EC75A;
      border: 1px solid rgba(49, 210, 87, 0.5);
    }

    //无计划
    .no-plan{
      color: #FAAD14;
      border: 1px solid rgba(250, 173, 20, 0.4);
    }

    // 无数据
    .no-data{
      color: #ffff;
    }

    .join{
      background: #0EC75A;
      color: #fff;
    }

    .departure{
      color: #fff;
      background-color: #F53731
    }

     .vacationrange-flirt{
        background: rgba(86, 173, 255, 0.2);
        width: 4px;
        height: 4px;
     }
     .waste{
     background: rgba(245, 55, 49, 0.2);
       color: #F53731;
     }
     .work-overtime{
        background: rgba(144, 134, 255, 0.2);
        color: #9288FF;
     }
     .unusual{
        background: rgba(250, 173, 20, 0.2);
        color: #FAAD14;
     }
     .flirt{
      background: rgba(86, 173, 255, 0.2);
       color: #56ADFF;
     }
     .rest{
       background: #F7F9FC;
       color: #F7F9FC;
     }

     .ant-pagination {
        text-align: right;
        margin-top: 16px;
     }
  }
  
}

// 资源日历
.calendar-data-contain{
  background-color: #fff;
  padding: 20px 20px 10px;
  margin-top:10px;

  
  &-title{
color: #333333;
font-weight: 500;
font-size: 16px;
  }
  &-main{
    .tab{
      display: flex;
      width: 100%;
      height: 37px;
      font-size: 14px;
      border-bottom: 1px solid #EBEDF0;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      margin: 26px -20px 0px;
      padding-left: 20px;
      .select-tab{
        border-bottom: 2px solid #FF6600;
        color: #FF6600;
        font-weight: 500;
        cursor: pointer;
      }
    }
    margin-top: 10px;
    &-title{
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
     .title-text{
       font-size: 16px;
       color: #333333;
       font-weight: 400;
       margin: 0 15px;
     }
    }
    &-search{
      position: absolute;
      right: 0;
      margin-top: -2px;
      .ant-select .ant-select-single .ant-select-show-arrow .ant-select-show-search {
        width: 140px;
      }
    }

    .events > li{
      margin-bottom: 6px;
    }
    
    .events .status {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 6px;
    }
   
    // tab切换样式调整
    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
     margin: 0;
    }

    .ant-tabs-content-holder{
    padding: 0px 20px;
     }
    .ant-tabs{
    margin: 0 -20px;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
    padding-left: 20px;
   }

     // 投入汇总+明细 公用的日历样式（日历组件改造） -------------------- 
     .ant-picker-calendar .ant-picker-panel .ant-picker-body{
       padding:0px;
     }
      .ant-picker-calendar{
        padding-bottom: 20px;
      }

     //第六列日期隐藏，原型只有五列
    // .ant-picker-calendar .ant-picker-panel .ant-picker-content>tbody>tr:last-child{
    //   display: none;
    // }

    //日历日期文案
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th:before {
    content: '周';
    }
    .ant-picker-content {
        border-bottom: 1px solid #f0f0f0;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th{
     color: #666666;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date{
        height: 222px;
        border-left: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        margin: 0px;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date:last-child{
        height: 222px;
        border-left: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        margin: 0px;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body .ant-picker-content > tbody > tr {
      border-right: 1px solid #f0f0f0;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
       border-left: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
       text-align: center;
       line-height: 34px;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body > .ant-picker-content > thead > tr {
      border-right: 1px solid #f0f0f0;
    }

    // 周六周日的样式
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body > .ant-picker-content > thead > tr > th:last-child{
      background-color: #F7F9FC;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body > .ant-picker-content > thead > tr > th:nth-child(6){
      background-color: #F7F9FC;
    }

    // 今日日期
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today{
      background-color: #fff;
      // border-image: linear-gradient(90deg, rgba(255, 132, 0, 1), rgba(255, 77, 0, 1)) 1 1;
      border: none !important;
      border-top: 1px solid #f0f0f0 !important;
      border-left: 1px solid #f0f0f0 !important;
      .ant-picker-calendar-date-value{
        color: rgba(0, 0, 0, 0.85);
        // margin-top: -4px;
      }
    }

    //自定义今天日期
    .setting-today{
    width: 26px;
    height: 26px;
    background: linear-gradient(90deg, #FF8400 0%, #FF4D00 100%);
    color: #FFFFFF !important;
    // font-size: 16px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    }
  
   
    .ant-picker-cell{
      color: #999999 ;
    }
    .ant-picker-cell-in-view{
      color: #666666;
    }
    
    //鼠标移动日期
    .ant-picker-cell-inner.ant-picker-calendar-date:hover {
        background-color: #fff !important;
        // transform: scale(1);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
     }
    
    // --------------------   投入汇总+明细 公用的日历样式

    // 投入汇总日历样式
    .calendar-data-putinto{
      .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content{
        height: 100%;
        margin-top: 8px;
      }
      .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date{
        padding: 14px;
        .vacation-status-box{
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          top: 16px;
          color: #FF6600;
        }
      }
    }

    //明细的日历样式
    .calendar-data-detail{
       .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date{
        padding: 14px 14px 12px 14px;
        .detail-status-box{
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          top: -24px;
          // left: 4px;
          .status{
         display: inline-block;
         width: 8px;
         height: 8px;
         border-radius: 4px;
         margin-right: 6px;
          }
        }
    }
    .reset-date-box{
    height: 234px;
    background: #FBFBFC;
    position: relative;
    top: -23px
    }
   
    .reset-status-box{
      position: relative;
      top: 12px;
      left: 15px;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content{
      overflow-y: visible;
      clear: both;
    }
    .detail-calendar-data-waste-box{
      background-image: url('../../img/waste-bgc.png');
      background-position: center;
      background-size: cover;
      height: 164px;
      margin-left: 6px;
      margin-right: 6px;
     }

      .detail-calendar-data-join-box{
        background-position: center;
        background-size: cover;
        height: 10px;
        margin-left: 6px;
        margin-right: 6px;
        background-color: #31CC6F;
      }

      .detail-calendar-data-departure-box{
        background-position: center;
        background-size: cover;
        height: 10px;
        margin-left: 6px;
        margin-right: 6px;
        background-color: #f53731;
      }

     .detail-calendar-data{
      // background-color: #F7F7F7;
      padding-left: 8px;
      display: flex;
      height: 80px;
      flex-direction: column;
      color: #666666;
    }
    .detail-calendar-data > span{
      height: 20px;
      overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    -o-text-overflow: ellipsis;/* for Opera */
    }

    .detail-calendar-data > span:not(:first-child){
      padding-left: 8px;
    }
    }

  }
}

//项目明细
.project-modal-contain-search{
  display: flex;
  flex-direction: row;
  .select-department-contain{
    display: flex;
    flex-direction: row;
    align-content: center;
    >span{
      display: inline-block;
      width: 80px;
      height: 32px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      border-right: none;
      text-align: center;
      line-height: 32px;
    }
  }
  .input-status-box .ant-select{
    width: 180px;
  }
  .ant-input-group-wrapper{
    width: 260px;
  }
  .input-status-box{
    display: flex;
    margin-right: 10px;
    .input-status-words{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 32px;
      background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-right: none;
    }
  }
}
.project-modal-contain-list{
  margin-top: 16px;
  &-pagination{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    // border-top: 1px solid #f0f0f0;
    padding-top: 14px;
  }
 .ant-table-thead{
       background-image: url('../../img/best_quality_top5.png');
         background-position: center;
          background-size: cover;
          line-height: 0px;
          height: 56px;
     }
     .ant-table-thead > tr > th{
       background: none;
       font-weight: 500;
      color: #FFFFFF;
      border-bottom: none;
     }
     .ant-table-thead > tr > th:first-child{
       padding-left: 20px;
     }
     .ant-table-thead > tr > th:last-child{
       padding-right: 20px;
     }
     .ant-table-tbody > tr > td:first-child{
      padding-left: 20px;
     }
     .ant-table-tbody > tr > td:last-child{
        padding-right: 20px;
     }

     .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
       padding: 16px 8px;
     }
}




  



@primary-color: #ff6600;