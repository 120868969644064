.skyeye_pc_home_sider_skyeye_pc_home_sider {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 20px 0px 10px;
    &__title {
        font-size: 14px;
        font-weight: 500;
        color: #FF6600;
        padding: 0 20px 10px 20px;
    }
    &__list {
        &--item {
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 44px;
            width: 100%;
            span {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
            }
            &:hover {
                background: #F2F2F2;
                a {
                    color: #333333;
                }
            }
        }
    }
}
@primary-color: #ff6600;