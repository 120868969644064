.skyeye_pc_welcome_page {
    width: 100%;
    height: calc(100% - 105px - 119px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    >img {
        margin-right: 12%;
    }
    &__content {
        width: 395px;
        background: #FFFFFF;
        box-shadow: 0px 22px 48px 0px rgba(212, 108, 33, 0.51);
        border-radius: 10px;
        z-index: 1000;
        position: relative;
    }
}
@primary-color: #ff6600;