// 表单上下间距
@margin_bottom: 26px;
// 表单 - 高度
@form_height: 50px;

.skyeye_pc_login_login_form {
  &__title {
    font-size: 24px;
    font-weight: normal;
    color: #333;
    padding-bottom: 40px;
    line-height: normal;
  }
  &__form {
    // 上下间距
    >.ant-form-item {
      margin-bottom: @margin_bottom;
      // 校验提示出现时，margin-botom为0
      &.ant-form-item-with-help {
        margin-bottom: 0 !important;
      }
      >.ant-form-item-control {
        .ant-form-item-control-input {
          >.ant-form-item-control-input-content {
            >.ant-input, >span {
              width: 100%;
              height: @form_height;
              border-radius: 4px;
              font-size: 14px;
            }
          }
        }
        // 校验提示
        >.ant-form-item-explain-error {
          min-height: @margin_bottom;
          line-height: normal;
        }
      }
    }
    &--submit {
      //margin-bottom: 81px !important;
      text-align: center;
      .ant-btn {
        width: 100%;
        height: @form_height;
        background-image: linear-gradient(90deg, #FFA261 0%, #F47415 56%, #F05020 100%);
        border-radius: 4px;
        border-width: 0;
        >span {
          font-size: 16px;
          color: #fff;
        }
      }
    }
    &--info {
      text-align: center;
      .ant-btn {
        width: 100%;
        height: @form_height;
        background-image: linear-gradient(90deg, #FFA261 0%, #F47415 56%, #F05020 100%);
        border-radius: 4px;
        border-width: 0;
        >span {
          font-size: 16px;
          color: #fff;
        }
      }
    }
    &--remember_me {
      margin-bottom: 0 !important;
      .ant-form-item-control-input {
        min-height: auto;
        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
          justify-content: center;
          >label {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            align-items: center;
            display: flex;
            .ant-checkbox + span {
              font-size: 14px;
              color: #333;
              padding-left: 8px;
              line-height: 14px;
            }
            >.ant-checkbox {
              top: 1px;
              input, .ant-checkbox-inner {
                width: 16px;
                height: 16px;
              }
              .ant-checkbox-inner {
                border-radius: 3px;
                border-color: #B8B8B8;
              }
            }
          }
          >div {
            color: #FF6600;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@primary-color: #ff6600;