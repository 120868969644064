.skyeye_pc_progress {
    height: 14px;
    line-height: 14px;
    background: #C9C9C9;
    border-radius: 12px;
    position: relative;

    &__active {
        height: 100%;
        background: linear-gradient(90deg, #FFA229 0%, #FE5500 100%);
        border-radius: 12px;
        display: flex;
        align-items: center;

        &--text {
            font-size: 12px;
            font-weight: 400;
            position: absolute;
            right: 4px;
            color: #fff;
        }
    }
}
@primary-color: #ff6600;