// less公共变量 / 方法
@import '~@style/common.less';

.skyeye_pc_footer {
    background: rgba(229, 236, 238, 0.6);
    width: 100%;
    height: @footer_copyright_height;
    border-top: 2px solid #fff;
    display: flex;
    align-items: center;
    &__copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding-bottom: 22px;
        p, div, span, a {
            font-size: 12px;
            color: #333;
            font-weight: 400;
        }
        >p {
            margin-bottom: 10px;
        }
    }
}
@primary-color: #ff6600;