.project_list_search_group {
    width: 100%;
    height: 72px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    align-items: center;
    margin-bottom: 10px;
    
    >.ant-form {
        >.ant-form-item {
            &:not(:last-child) {
                margin-right: 9px !important;
                flex: 1;
                .ant-select-selector {
                    border-radius: 4px !important;
                }
            }
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    &__datePicker {
        width: 100%;
        border-radius: 4px !important;
    }

    &__button {
        width: 70px;
        border-radius: 4px !important;
    }
}
@primary-color: #ff6600;