.bug_fixes {

  .bug_fixes_tips{
    padding: 20px;
    background-color: #fff;
    .ant-alert-with-description .ant-alert-message{
      margin-bottom: 0px;
    }
    .bug_fixes_tips_title{
      margin-bottom: 14px;
      font-weight: 500;
      color: #333333;
      font-size: 16px;
     
    }
    .ant-alert-with-description.ant-alert-no-icon{
      padding: 0px;
    }
    .data-description{
      color: #FF6600;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      height: 32px;
    }
    .data-description > span{
      margin-right: 4px;
    }
  }

  // 搜索组合样式
  .input_content {
    height: 72px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    margin-top: 10px;
    display: flex;

    &_item {
      width: 100%;
      margin-right: 10px;
      border-radius: 4px;
    }
    .search_group_button {
      width: 70px;
      border-radius: 4px;
    }
  }
}


.bug_fixes_total_data{
      margin-top: 10px;
      background-color: #ffff;
      padding: 20px;
      //表头样式

      .bug_fixes_total_data_title{
        font-weight: 500;
        color: #333333;
        margin-bottom: 14px;
        font-size: 16px;
        
      }
      .ant-table-thead{
       background-image: url('../../img/bug_fixes_table.png');
         background-position: center;
          background-size: cover;
          line-height: 0px;
          // height: 60px;
     }
     .ant-table-thead > tr > th{
       background: none;
       font-weight: 500;
      color: #FFFFFF;
      border-bottom: none;
      // padding:0px;
     }
    // 分页组件样式调整
    .ant-pagination{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;
      padding-top: 14px;
    }
    .table-rate-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
        .rate-market{
       height: 16px;
        width: 28px;
        margin-left: 4px;
    }
    .rate-bad-market{
        height: 16px;
        width: 40px;
        margin-left: 4px;
        }
    }

      tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
        background:#ffff;
      }

      .detail-select-contain{
        .search_group_button{
          margin-left: 14px;
        }
      }

      // 列表有关antd组件样式更改
      .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        padding: 16px  0;
      }
      .ant-table-container table > thead > tr:first-child th:first-child{
        padding-left: 20px;
      }
      .ant-table-tbody > tr > td:first-child{
        padding-left: 20px;
      }
      .ant-table-row-expand-icon-spaced{
         display: none;
      }
      .ant-table-row-expand-icon .ant-table-row-expand-icon-spaced{
       display: none;
     }

     // 点击数据列表后相关样式的调整
     .click-row-bgc{
       background-color: #fff4e6;
     }
     .ant-table-tbody{
     > tr:not(.click-row-bgc):hover >td{
     background:#fafafa!important;
     }
    }
    .ant-table-tbody{
     > .click-row-bgc:hover >td{
     background:#fff4e6!important;
     }
   }
   
   .ant-table-tbody {
       >tr {
           >td {
               height: 56px;
               padding-top: 0;
               padding-bottom: 0;
           }
       }
   }


}

// 缺陷明细样式
.detail-list-contain{
  height: 100%;
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  .detail-list-sider{
    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
      width: 100px;
    }
    .ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder{
      width: 0px;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab{
      padding-left: 12px;
      margin: 0;
      height: 40px;
    }
  }
  .detail-list-main{
    
    width: 100%;
    height: 100%;
    padding-left: 10px;
    .detail-list-tab{
      border-bottom: 1px solid #f0f0f0;
      height: 40px;
      .detail-list-tab-ul{
       display: flex;
      flex-direction: row;
      }
      .detail-list-tab-ul > li{
        width: 100px;
        text-align: center;
      }
    }
    .statistical-range{
      display: flex;
      justify-content: flex-end;
      margin-top: -40px;
    }

    .detail-list-main-alert{
      width: 100%;
      height: 84px;
      background-color: #FFF6F0;
      border:1px solid #ffbd7a;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 20px;
      margin-bottom: 10px;
      margin-top: 14px;
      .detail-list-main-alert-num{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
     .detail-list-main-alert>div{
        flex: 1;
        display: flex;
        flex-direction: column;
       
      }
      .detail-list-main-alert-title{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .detail-list-main-alert-title > p {
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
      }
      .detail-list-main-alert-title>span{
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 6px;
        background: url(../../img/no-alert.png);
        background-position: center;
        background-size: cover;
      }
      .detail-list-main-alert-title>span:hover{
         display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 6px;
        background: url(../../img/alert.png);
        background-position: center;
        background-size: cover;
      }  

      .detail_data_list{
        .ant-table-thead > tr > th{
          height: 56px;
        }
        .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        padding: 16px  16px;
         }
      }
  }
}

.bug_fixes_trend_chart{
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  // width: 100%;
  .title{
    font-weight: 500;
    color: #333333;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .chart{
    width: 100%;
   
  }
}

.hover-cls:hover {
  color: #FF6600;
  //border-bottom: 1px solid #FF6600;
}



@primary-color: #ff6600;