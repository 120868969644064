.project_list_iteration_tabs {
    >.ant-tabs {
        >.ant-tabs-nav {
            margin: 0 !important;
            >.ant-tabs-nav-wrap {
                >.ant-tabs-nav-list {
                    >.ant-tabs-tab {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                        line-height: 20px;
                        padding: 14px 0;
                    }
                    >.ant-tabs-tab-active {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
@primary-color: #ff6600;