.skyeye_pc_change_password {
    width: 100%;
    height: 425px;
    >div {
      background: #fff;
      height: 100%;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      padding: 30px 80px 40px;
    }
    &__Steps {
      border-bottom: 1px solid #EEEFF3;
      width: 100%;
      align-items: center;
      padding-bottom: 30px !important;
      &--Step, &--Step_active {
        >.ant-steps-item-container {
          display: flex;
          align-items: center;
          >.ant-steps-item-icon {
            width: 44px !important;
            height: 44px !important;
            margin-right: 12px;
            >span {
              display: inline-block;
              width: 100% !important;
              height: 100% !important;
            }
          }
          >.ant-steps-item-content {
            >div.ant-steps-item-title {
              font-size: 18px;
              font-weight: 500;
              line-height: 25px;
              color: #666;
              padding-right: 10px;
              &::after {
                border-bottom: 2px dotted #C9C9C9;
                background: transparent;
                top: 50%;
              }
            }
          }
        }
      }
      &--Step_active {
        >.ant-steps-item-container {
          >.ant-steps-item-content {
            >div.ant-steps-item-title {
              color: #24A4FF;
              &::after {
                border-bottom-color: #24A4FF;
              }
            }
          }
        }
      }
    }
    &__form_list {
      height: 240px;
      padding-top: 30px;
    }
    &__next {
      text-align: center;
      color: #fff;
      font-size: 16px;
      width: 120px;
      height: 40px;
      line-height: 38px;
      background: linear-gradient(90deg, #FFA261 0%, #F47415 56%, #F05020 100%);
      border-radius: 4px;
      border-width: 0;
      cursor: pointer;
      margin: 0 auto;
    }
}
@primary-color: #ff6600;