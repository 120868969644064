// less公共变量 / 方法
@import '~@style/common.less';

// 回到顶部 - 按钮宽度
@back_top_width: 44px;

.skyeye_pc_back_top {
    width: auto !important;
    height: auto !important;
    right: 40px !important;
    bottom: calc(@footer_copyright_height + 60px) !important;
}
@primary-color: #ff6600;