.skyeye_pc_complete_steps03 {
    text-align: center;
   img {
     margin-bottom: 20px;
   }
   div {
    font-size: 16px;
    font-weight: 500;
    color: #666660;
    line-height: 22px;
   }
}
@primary-color: #ff6600;