.skyeye_pc_app {
    // 状态文本 - 公共样式
    .iteration_status_text_common_style(@color: #1677FE) {
        width: 40px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: @color;
        border-radius: 2px;
        border: 1px solid @color;
    
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    // 已验收、已完成、已实现
    .iteration_status_text_01 {
        .iteration_status_text_common_style(#0EC75A);
    }
    
    // 暂停、烂尾
    .iteration_status_text_02 {
        .iteration_status_text_common_style(#FF4D4F);
    }
    
    // 维稳中
    .iteration_status_text_03 {
        .iteration_status_text_common_style(#FAAD14);
    }
    
    // 实施中、验收中、进行中
    .iteration_status_text_04 {
        .iteration_status_text_common_style(#1677FE);
    }
    
    // 未开始
    .iteration_status_text_05 {
        .iteration_status_text_common_style(#666666);
        border-color: #E0E0E0;
    }

}

@primary-color: #ff6600;