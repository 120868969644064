.skyeye_pc_set_a_new_password_steps02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    >.ant-form-item {
      margin-bottom: 38px;
      >.ant-form-item-label {
        width: 100px;
        label {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          height: 34px;
        }
      }
      >.ant-form-item-control {
        width: 372px;
        flex: none;
        >.ant-form-item-control-input {
          min-height: 34px;
          width: 100%;
          height: 34px;
          >.ant-form-item-control-input-content {
            height: 100%;

            // input相关样式
            .ant-input {
              height: 100%;
            }
            .ant-input[disabled] {
              background-color: #fff;
              cursor: text;
              border: none;
              padding-left: 0;
              padding-right: 0;
              font-size: 16px;
              color: #333;
              font-weight: bold;
            }
          }
        }
        // 校验提示
        .ant-form-item-explain-error {
          font-size: 14px;
          color: #F53731;
          position: absolute;
          bottom: -28px;
          min-height: auto;
          line-height: normal;
        }
      }
    }
    &__new_password {
      >.ant-form-item-control {
        >.ant-form-item-control-input {
          width: 372px;
          min-height: 34px;
          height: 34px;
          >.ant-form-item-control-input-content {
            height: 100%;
            .ant-input {
              height: 100%;
            }
          }
        }
      }
      &--tip {
        display: flex;
        align-items: center;
        margin-top: 8px;
        >img {
          margin-right: 6px;
        }
        >span {
          font-size: 13px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
}
@primary-color: #ff6600;