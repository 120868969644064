.skyeye_pc_home_sider_rank {
    width: 100%;
    height: 145px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__item {
        padding: 20px;
        flex: 1;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &__title, &__content {
            color: #fff;
        }
        &__title {
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 14px;
            >span {
                font-size: 12px;
            }
        }
        &__content {
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
        }
    }
}
@primary-color: #ff6600;