// 表单上下间距
@margin_bottom: 28px;
// 表单 - 高度
@form_height: 34px;

.skyeye_pc_change_password_steps01 {
    width: 73%;
    margin: 0 auto !important;
    &--new_password__tip {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      display: flex;
      align-items: center;
      position: absolute;
      right: -100%;
      img {
        margin-right: 6px;
      }
    }
    >.ant-form-item {
      margin-bottom: @margin_bottom;
      // 校验提示出现时，margin-botom为0
      &.ant-form-item-with-help {
        margin-bottom: 0 !important;
      }
      >.ant-form-item-label {
        width: 200px;
        label {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          height: @form_height;
          line-height: @form_height;
        }
      }
      >.ant-form-item-control {
        >.ant-form-item-control-input {
          width: 214px;
          min-height: @form_height;
          height: @form_height;
          >.ant-form-item-control-input-content {
            height: 100%;
            line-height: @form_height;
            display: flex;
            position: relative;
            >span {
              font-size: 16px;
              color: #333;
              font-weight: bold;
            }

            // input相关样式
            .ant-input {
              height: 100%;
            }
            .ant-input[disabled] {
              background-color: #fff;
              cursor: text;
              border: none;
              padding-left: 0;
              padding-right: 0;
              font-size: 16px;
              color: #333;
              font-weight: bold;
            }

          }
        }
        // 校验提示
        >.ant-form-item-explain-error {
          min-height: @margin_bottom;
          line-height: normal;
        }
      }
    }
    &__code {
      .ant-form-item-control {
        >.ant-form-item-control-input {
          >.ant-form-item-control-input-content {
            display: flex;
          }
        }
      }
      &--input {
        >.ant-form-item {
          width: 136px;
          .ant-form-item-control {
            >.ant-form-item-control-input {
              width: 100% !important;
              min-height: @form_height;
              height: @form_height;
              >.ant-form-item-control-input-content {
                height: 100%;
                .ant-input {
                  height: 100%;
                }
              }
            }
          }
        }
      }
      &--btn, &--disabled {
        width: 66px;
        height: @form_height;
        line-height: 33px;
        text-align: center;
        border-radius: 2px;
        margin-left: 12px;
        border: 1px solid #4A9AF7;
      }
      &--btn {
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        background: linear-gradient(90deg, #FFA261 0%, #F47415 56%, #F05020 100%);
        border-width: 0;
      }
      &--disabled {
        background: #F1F0F0;
        border-color: #D7D7D7;
        color: #999999;
        cursor: text;
        font-size: 16px;
      }
    }
}
@primary-color: #ff6600;