.skyeye_pc_loginscan_and_login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &__content {
    margin-bottom: 37px;
    &--title {
      font-size: 24px;
      font-weight: normal;
      color: #333;
      padding-bottom: 40px;
    }
    &--code_QR {
      width: 100%;
      background: #FFFFFF;
      border-radius: 6px;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &__code_dingding, &__code_wechat {
        overflow: hidden;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      &__code_dingding {
        width: 236px;
        height: 290px;
        margin: -40px auto 0;
      }
      &__code_wechat {
        width: 100%;
        height: 308px;
        margin: -48px auto 0;
      }
    }
    &--loading {
      width: 236px;
      height: 290px;
      line-height: 290px;
      text-align: center;
    }
  }
  &__tabs {
    text-align: center;
    >span {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      margin-left: 8px;
    }
  }
}
@primary-color: #ff6600;