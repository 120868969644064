// 表单上下间距
@margin_bottom: 16px;

.skyeye_pc_register_form {
  // 标题
  &__title {
    font-size: 24px;
    font-weight: normal;
    color: #333;
    padding-bottom: 20px;
  }
  &__form {
    // 上下间距
    >.ant-form-item {
      margin-bottom: @margin_bottom;
      // 校验提示出现时，margin-botom为0
      &.ant-form-item-with-help {
        margin-bottom: 0 !important;
      }
      >.ant-form-item-control {
        // 校验提示
        >.ant-form-item-explain-error {
          min-height: @margin_bottom;
          line-height: normal;
        }
      }
    }
    // input输入框
    &--input {
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border-color: #B8B8B8;
      font-size: 14px;
    }
    // 下拉框
    &--select {
      font-size: 14px;
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 44px !important;
          line-height: 44px;
          border-radius: 4px;
          border-color: #B8B8B8;
          .ant-select-selection-placeholder, .ant-select-selection-item {
            line-height: 44px;
          }
        }
        // 下拉箭头
        .ant-select-arrow {
          width: auto;
          height: auto;
          top: 50%;
        }
        .ant-select-clear {
          width: auto;
          height: auto;
          font-size: 14px;
        }
      }
    }
    // 日期选择框
    &--datePicker {
      font-size: 14px;
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border-color: #B8B8B8;
    }
    // 注册按钮
    &--submit {
      text-align: center;
      margin-bottom: 20px !important;
      .ant-btn {
        width: 100%;
        height: 44px;
        background-image: linear-gradient(90deg, #FFA261 0%, #F47415 56%, #F05020 100%);
        border-radius: 4px;
        border-width: 0;
        >span {
          font-size: 16px;
          color: #fff;
        }
      }
    }
    // 温馨提示
    &--tip {
      margin-bottom: 0 !important;
      >div {
        font-size: 14px !important;
        color: #999;
        line-height: 20px !important;
      }
    }
  }
}
@primary-color: #ff6600;