.quality_assurance_project_list {
  &__content {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 20px;
    min-height: 631px;
  }
}

@primary-color: #ff6600;