.skyeye_pc_home_content {
  width: 650px;
  &__list {
    &--item {
      padding: 20px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      margin-bottom: 10px;
      width: 100%;
      &__title {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        .ant-col-18 {
          > a {
            font-size: 16px;
            color: #333;
            font-weight: bold;
            cursor: pointer;
            line-height: 22px;
            &:hover {
              color: #ff6600;
            }
          }
        }
        .ant-col-6 {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > span {
            border: 1px solid #ffffff;
            img {
              -webkit-user-drag: none;
            }
          }
          > div {
            margin-left: 8px;
            p {
              font-size: 13px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 2px;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
      &__content {
        .ant-col-24 {
          > a {
            font-size: 12px;
            font-weight: 400;
            color: #333;
            line-height: 21px;
            max-width: 480px;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            &:hover {
              color: #666666;
            }
          }
        }
      }
      
    }
    &--item:last-child{
      margin-bottom: 0px;
    }
    &--more {
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;

      font-size: 16px;
      font-weight: 400;
      color: #ff6600;
      cursor: pointer;
      margin-top: 10px;
    }
  }
}

@primary-color: #ff6600;