.skyeye_pc_quality-assurance {
  .quality_inspection {
    .input_group {
      height: 72px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      padding: 20px;
      display: flex;

      &_item {
        width: 100%;
        border-radius: 4px;
        margin-right: 10px;
      }

      .search_group_button {
        width: 70px;
        border-radius: 4px;
      }
    }

    .project_information {
      margin: 10px 0;
      padding: 20px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      &_title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-bottom: 4px;
      }
      &_box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 40px;
        div {
          color: #999999;
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
          width: 30%;
          margin-top: 10px;
        }
        span {
          color: #333333;
        }
      }
    }

    .iterative_information {
      margin: 10px 0;
      padding: 20px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      &_title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 4px;
      }
      &_box {
        padding: 0 40px;
        &_item {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          div {
            color: #999999;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            width: 30%;
            margin-top: 10px;
          }
          > div:first-child {
            margin-top: 10px;
          }
          span {
            margin-top: 10px;
            color: #333333;
          }
        }
      }
    }

    .test_information {
      &_table {
        margin: 10px 0;
        padding: 20px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        &_title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          margin-bottom: 14px;
        }
        &_box {
          &_title {
            width: 100%;
            height: 56px;
            background-image: url('../../img/test_information_table.png');
            background-size: 100%;
          }
          .ant-table-tbody > tr > td {
            padding-left: 20px;
            position: relative;
          }
          .ant-table-row-expand-icon-spaced {
            display: none;
          }
        }
        &_pagination {
          display: flex;
          justify-content: flex-end;
          margin-top: 14px;
        }
      }
    }

    .bug_data_list {
      margin-top: 10px;
      padding: 20px;
      background-color: #ffffff;
      &_title {
        display: flex;
        justify-content: space-between;
        &_words {
          font-weight: 500;
          color: #333333;
          font-size: 16px;
        }
        .ant-checkbox-wrapper:first-child {
          margin-right: 8px;
        }
        .ant-checkbox-group-item:last-child {
          margin-right: -8px;
        }
        .ant-checkbox + span {
          padding-left: 8px;
          padding-right: 10px;
        }
      }
      &_table {
        margin-top: 14px;
        .ant-table-thead {
          background-image: url('../../img/bug_data.png');
          background-position: center;
          background-size: cover;
        }
        .ant-table-thead > tr > th {
          background: none;
          font-weight: 500;
          color: #ffffff;
          border-bottom: none;
          text-align: center;
        }
        .ant-table-thead > tr > th {
          padding: 0px 0px;
        }
        .ant-table-thead > tr {
          height: 35px;
        }
        .ant-table-thead>tr:first-child{
          th:nth-of-type(1){
            padding-left: 20px;
          }
        }
        
        // 点击数据列表后相关样式的调整
        .click-row-bgc {
          background-color: #fff4e6;
        }
        .ant-table-tbody {
          > tr:not(.click-row-bgc):hover > td {
            background: #fafafa !important;
          }
        }
        .ant-table-tbody {
          > .click-row-bgc:hover > td {
            background: #fff4e6 !important;
          }
        }
        
        .ant-table-tbody {
            >tr {
                >td {
                    height: 56px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
      }
      &_pagintion {
        margin-top: 14px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.hover-cls:hover {
  color: #FF6600;
  //border-bottom: 1px solid #FF6600;
}
@primary-color: #ff6600;