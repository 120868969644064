.skyeye_pc_home_sider_platform {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: 204px;
    
    &__item {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        margin-bottom: 22px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin-right: 19px;
        img {
            margin-bottom: 8px;
        }
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            line-height: 12px;
            display: block;
        }
        span:hover{
            color: #666666;
        }
        
    }
    &__item:hover{
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    }
    &__item:nth-child(4n+0){
        margin-right: 0px;
    }
}
@primary-color: #ff6600;