.skyeye_pc_home_sider_git_calendar_heatmap {
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  margin-bottom: 10px;
  &__title {
    font-size: 14px;
    font-weight: 500;
    color: #ff6600;
    padding-bottom: 6px;
  }
  .react-calendar-heatmap {
    rect {
      &:hover {
        cursor: pointer;
        stroke: #000;
      }
      &:focus {
        outline: none;
      }
      &.color_empty {
        fill: #ededed;
      }

      &.color_github_0_10 {
        fill: #acd5f2;
      }

      &.color_github_11_20 {
        fill: #7fa8d1;
      }
      &.color_github_21_29 {
        fill: #49729b;
      }
      &.color_github_30 {
        fill: #254e77;
      }
    }
    .react-calendar-heatmap-month-label {
      font-size: 8px;
    }
  }
}

@primary-color: #ff6600;