.resource-calendar-select-month{
  .ant-picker-header{
    display: none;
  }
}
.project-iteration-select-dropdown{
   width: 302px !important;
}
// 所属部门下拉
.department-select-dropdown{
  width: 270px !important;
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{
  background: #FFF3EB !important;
  font-weight: 500;
}
}

// 员工下拉
.user-select-dropdown{
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background: #FFF3EB !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background: #FFF3EB !important;
}
.ant-select-item{
 color: #333333 !important;
}
}
.quality-inspection-bug-data-tooltip{
  span {
    color: #FF6600;
  }
}
// 缺陷分析-饼图提示框样式
.bug-analysis-pie-tooltip{
  border-color: none !important;
  padding: 14px !important;
  border: none !important;
  >div>div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span{
    color: #666666 !important;
    font-size: 12px !important;
    display: inline-block !important;
    font-weight: 400 !important;
  }
  div span:nth-child(1){
    width: 6px !important;
    height: 6px !important;
  }
  div span:nth-child(3){
    margin-left: 50px !important;
    text-align: center !important;
  }
}

// 缺陷分析-柱状图提示框样式
.bug-analysis-bar-chart-tooltip{
padding: 14px !important;
color: #666666 !important;
font-size: 12px !important;
 max-height: 324px !important;
 overflow-y: scroll !important;
// 人名标题
>div>div>div{
  font-weight: 400 !important;
  color: #000000 !important;
  font-size: 12px !important;
}
>div>div>div:nth-child(2){
  >div>div span:nth-child(1){
   width: 6px !important;
   height: 6px !important;
  }
   >div>div span:nth-child(2){
    font-size: 12px !important;
  }
  >div>div span:nth-child(3){
   margin-left: 50px !important;
   font-weight: 400 !important;
   font-size: 12px !important;
  }
}
}
// 自定义滚动条样式
.bug-analysis-bar-chart-tooltip::-webkit-scrollbar{
  /* 隐藏默认的滚动条 */
  -webkit-appearance: none;
}
.bug-analysis-bar-chart-tooltip::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 4px;
}
.bug-analysis-bar-chart-tooltip::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #999999;
}