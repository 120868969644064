// less公共变量 / 方法

// 主体内容区 - 宽度
@main-width: 960px;
// 主体内容区 - 宽度-适配
@main-width-fit: 1200px;

// 头部导航 - 高度
@header_nav_height: 60px;

// 底部版权 - 高度
@footer_copyright_height: 160px;

// 单行省略
// 注：入参命名要加前缀@ 且 需给定默认值
.single_line_ellipsis(@width: 100%) {
    width: @width;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}