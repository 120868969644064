.skyeye_pc_login {
    width: 100%;
    height: 100vh;
    background: linear-gradient(87deg, #FFB96D 0%, #FF9649 66%);
    overflow: hidden;
    &__content {
        >img {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
    }
}
@primary-color: #ff6600;