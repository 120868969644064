.bug_analysis {
  .bug_analysis_tips{
    padding-bottom: 0px;
    background-color: #fff;
    .ant-tabs{
      color: #333333;
      font-size: 16px;
    }
    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
      margin: 0px;
      padding-left: 20px;
      height: 56px;
    }
    
  }

  // 搜索组合样式
  .input_content {
    height: 72px;
    background: #ffffff;
    padding: 20px;
    display: flex;

    &_item {
      width: 100%;
      margin-right: 10px;
      border-radius: 4px;
    }
    .search_group_button {
      width: 70px;
      border-radius: 4px;
    }
  }
}

// 项目列表
.bug_analysis_project_list{
      background-color: #ffff;
      padding: 20px;
      padding-top: 0px;
      &_header{
        display: flex;
        justify-content: space-between;
        height: 32px;
        margin-bottom: 14px;
        align-items: center;
        // 按钮组合样式改造
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          color: #FF6600;
          background: #FFFFFF;
        }
        .ant-radio-button-wrapper{
          width: 100px;
          text-align: center;
          color: #333333;
          // border-radius: 4px 0px 0px 4px;
        }
        .ant-radio-button-wrapper:hover{
          background-color: #fff;
           color: #333333;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
          background-color: #fff;
           color: #333333;
        }
      }
      &_title{
        font-weight: 500;
        color: #333333;
        font-size: 16px;
      }
      .table-list{
        .project-name{
          display: flex;
          align-items: center;
          > img{
            width: 40px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
      .ant-table-thead{
      //  background-image: url('../../img/bug_fixes_table.png');
       background-image: url('../../img/project-table-thead.png');
         background-position: center;
          background-size: cover;
          // line-height: 0px;
          // height: 60px;
     }
     .ant-table-thead > tr > th{
       background: none;
       font-weight: 500;
      color: #FFFFFF;
      border-bottom: none;
      // padding:0px;
     }
    // 分页组件样式调整
    .ant-pagination{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;
      padding-top: 14px;
    }
    .table-rate-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
        .rate-market{
       height: 16px;
        width: 28px;
        margin-left: 4px;
    }
    .rate-bad-market{
        height: 16px;
        width: 40px;
        margin-left: 4px;
        }
    }

      tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
        background:#ffff;
      }

      .detail-select-contain{
        .search_group_button{
          margin-left: 14px;
        }
      }

      // 列表有关antd组件样式更改
      .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        padding: 16px  0;
      }
      .ant-table-container table > thead > tr:first-child th:first-child{
        padding-left: 20px;
      }
      .ant-table-tbody > tr > td:first-child{
        padding-left: 20px;
      }
      .ant-table-row-expand-icon-spaced{
         display: none;
      }
      .ant-table-row-expand-icon .ant-table-row-expand-icon-spaced{
       display: none;
     }

     // 点击数据列表后相关样式的调整
     .click-row-bgc{
       background-color: #fff4e6;
     }
     .ant-table-tbody{
     > tr:not(.click-row-bgc):hover >td{
     background:#fafafa!important;
     }
    }
    .ant-table-tbody{
     > .click-row-bgc:hover >td{
     background:#fff4e6!important;
     }
   }
   
   .ant-table-tbody {
       >tr {
           >td {
               height: 56px;
               padding-top: 0;
               padding-bottom: 0;
           }
       }
   }


}

// 概览
.bug_analysis_overview{
  padding: 20px;
  background-color: #ffff;
  margin-top: 10px;
  .ant-tabs{
    margin-left: -20px;
    margin-right: -20px;
  }
  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    padding-left: 20px;
  }
.ant-tabs-tab{
  padding-top: 0;
  padding-bottom: 16px;
}
.headline{
  padding-bottom: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEDF0;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  font-weight: 500;
  color: #333333;
  font-size: 16px;
}
.search-group{
  display: flex;
  align-items: center;
  .attention-box{
    display: flex;
    width: 105px;
    height: 32px;
    background-color: pink;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 4px;
    margin-left: 12px;
    cursor: pointer;
    >img{
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}
.overview-total{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .total{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .total-first{
     display: flex;
     flex-direction: row;
     .total-first-item{
       width: 185px;
       height: 150px;
       margin-right: 10px;
       background: linear-gradient(180deg, #FFF6F0 0%, #FFFFFF 100%);
       display: flex;
       flex-direction: column;
       padding: 20px;
       border: 1px solid #EBEDF0;
       border-radius: 4px;
       .title{
         color: rgba(0, 0, 0, 0.4);
         font-size: 16px;
       }
       .data{
         font-size: 30px;
         font-weight: 500;
         color: rgba(0, 0, 0, 0.8);
         margin-top: 14px;
       }
       .children-title{
         color: rgba(0, 0, 0, 0.4);
         font-size: 14px;
       }
       .children-data{
         font-size: 20px;
         font-weight: 500;
         color: rgba(0, 0, 0, 0.8);
         margin-top: 16px;
       }
     }
    }
  }
  .severity-chart{
    width: 380px;
    // background-color: pink;
    height: 310px;
    padding: 14px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title{
      font-size: 16px;
      color: #333333;
      font-weight: 500;
      }
      .ant-radio-wrapper{
        margin-right: 0px;
      }
     .ant-radio-wrapper:last-child{
       >span:last-child{
         padding-right: 0px;
       }
     }
     
    }
   
  }
   .ant-list{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
}

.overview-pie-chart{
  display: flex;
  margin-top: 10px;
  .chart-item{
    padding: 14px;
    width: 380px;
    height: 310px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    margin-right: 10px;
    .title{
      color: #333333;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .chart-item:last-child{
    margin-right: 0px;
  }
   .ant-list{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
}
}


// 模块柱状图
.module-chart{
  padding: 20px;
  background-color: #ffffff;
  margin-top: 10px;
  .empty-data{
    height: 400px;
    .ant-list{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-weight: 500;
      color: #333333;
      font-size: 16px;
    }
     // 按钮组合样式改造
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          color: #FF6600;
          background: #FFFFFF;
        }
        .ant-radio-button-wrapper{
          width: 100px;
          text-align: center;
          color: #333333;
          padding: 0px;
          // border-radius: 4px 0px 0px 4px;
        }
        .ant-radio-button-wrapper:hover{
          background-color: #fff;
           color: #333333;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
          background-color: #fff;
           color: #333333;
        }
  }
  .chart{
    width: 100%;
    margin-top: 10px;
    // background-color: pink;
    height: 100%;
  }
  .list{
    margin-top: 20px;
    .ant-table-tbody > tr:last-child>td{
      font-weight: 500;
    }
    .ant-table-thead{
      height: 54px;
    }
  }
  .pagination{
    margin-top: 14px;
    display: flex;
    justify-content: flex-end;
  }
}

.hover-cls:hover {
  color: #FF6600;
  //border-bottom: 1px solid #FF6600;
}

//折线图部分
&__line_charts {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  //padding: 20px;
  margin-bottom: 10px;

  &__tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;

    &__tag {
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      padding: 20px 20px 12px;
      height: 145px;
      flex: 1;
      &:not(:last-child) {
        margin-right: 10px;
      }

      &_title {
        font-size: 16px;

        font-weight: 400;
        color: #999999;
        line-height: 16px;
        display: flex;
        margin-bottom: 16px;
        >img {
          margin-left: 8px;
        }
        // 气泡样式
        &--tooltip {
          font-size: 12px !important;
          >.ant-tooltip-content {
            >.ant-tooltip-arrow {
              >.ant-tooltip-arrow-content {
                width: 10px;
                height: 10px;
                background-color: rgba(0, 0, 0, 0.8);
                border-radius: 0 0 2px 0;
              }
            }
            >.ant-tooltip-inner {
              padding: 8px 12px;
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.8);
            }
          }
        }
      }

      &_main_data {
        font-size: 30px;

        font-weight: 500;
        color: #333333;
        line-height: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;

        .main_data_label {
          font-size: 14px;

          font-weight: 400;
          color: #666666;
          line-height: 22px;
          margin-left: 20px;
          position: relative;

          &_title {
            font-size: 14px;

            line-height: 22px;
            margin-left: 8px;

            &_red {
              color: #F53731;
            }

            &_green {
              color: #6BE653;
            }
          }

          &_triangle_red {
            border-bottom: 5px solid #F53731;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            position: absolute;
            margin-left: 4px;
            bottom: 40%;
          }

          &_triangle_green {
            border-top: 5px solid #6BE653;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            position: absolute;
            margin-left: 4px;
            top: 40%;
          }
        }
      }

      &_bottom {
        .range_picker{
          padding-top: 10px;
          border-top: 1px solid rgba(255, 102, 0, 0.1);
          display: flex;
          justify-content: space-between;
          min-width: 192.5px;

          span {
            font-size: 14px;

            font-weight: 400;
            color: #666666;
            line-height: 20px;
            cursor: pointer;
          }

          .checked {
            font-size: 14px;

            font-weight: 400;
            color: #FF6600;
            line-height: 20px;
            cursor: pointer;
          }
        }

        .date {
          font-size: 12px;

          font-weight: 400;
          color: #FF6600;
          line-height: 22px;
          padding-top: 10px;
        }
      }
    }

    &__tag_checked {
      background: #FFF6F0;
      border-radius: 4px;
      border: 1px solid #FF6600;
    }
  }

  &_chart {
    &__title {
      font-size: 14px;

      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
}


@primary-color: #ff6600;