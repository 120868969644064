.project_list_iteration_list {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 20px;
    margin-bottom: 10px;

    &__title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
    }

    &__table {
        padding: 14px 0 0;

        .ant-table-thead {
            background-image: url('../../../../img/project-table-thead.png');
            background-position: center;
            background-size: cover;
            height: 56px;

            >tr {
                >th {
                    background: transparent;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 20px;
                    border-bottom: none;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .ant-table-tbody {
            >tr {
                cursor: pointer;
                >td {
                    height: 56px;
                    padding-top: 0;
                    padding-bottom: 0;

                    &:first-child {
                        padding-right: 0;
                    }
                }
            }
        }

        .ant-pagination {
            margin: 14px 0 0 0 !important;
        }

        &--activeRow {
            background: #fff4e6;
        }

        &--status {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
@primary-color: #ff6600;