// 表格表头高度
@table_columns_height: 56px;

.skyeye_pc_effectiveness_measurement {
  &__tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px;

    &_item {
      font-size: 14px;

      color: #666666;
      line-height: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
    &_item:hover {
      color: #ff6600;
    }

    .activeKey {
      font-size: 16px;

      font-weight: 500;
      color: #ff6600;
      line-height: 22px;
    }
  }

  // 搜索组合样式
  .input_content {
    height: 72px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    margin-bottom: 10px;

    &_item {
      width: 208px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .search_group_button {
      width: 70px;
      border-radius: 4px;
    }
  }
  .search-time-box {
    background: #ffffff;
    padding: 20px 20px 0px;
    .search_group_button {
      width: 70px;
      border-radius: 4px;
      margin-left: 10px;
    }
  }

  //个人排行榜
  .personal_ranking {
    //年度数据
    &__annulData {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      background-color: #fff;
      padding: 20px;
      padding-bottom: 0px;

      .issue_img {
        background-image: url('./img/issue.png');
      }

      .rank_img {
        background-image: url('./img/rank.png');
      }

      .rate_img {
        background-image: url('./img/rate.png');
      }

      .task_img {
        background-image: url('./img/task.png');
      }

      .time_img {
        background-image: url('./img/time.png');
      }
      &__item {
        height: 120px;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 20px;
        text-align: left;
        flex: 1;
        &:not(:last-child) {
          margin-right: 10px;
        }

        &__title {
          font-size: 16px;

          color: #fff;
          margin-bottom: 14px;
          white-space: nowrap;
          span {
            font-size: 12px;
          }
        }
        &__content {
          font-size: 30px;

          font-weight: 500;
          color: #fff;
          line-height: 30px;
        }
      }
    }

    // 研发效能排行榜
    &__effect-list {
      padding: 20px;
      background-color: #fff;
      margin-bottom: 10px;
      &__title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 14px;
      }
      .ant-table-thead {
        // background-image: url('./img/personal_score_data.png');
        background-image: url('./img/effect-list-thead.png');
        background-size: 100% 100%;
        line-height: 0px;
        height: 70px;
      }
      .ant-table-thead > tr > th {
        background: none;
        font-weight: 500;
        color: #ffffff;
        border-bottom: none;
        padding: 0px;
        text-align: center;
      }
      // 点击数据列表后相关样式的调整
      .click-row-bgc {
        background-color: #fff4e6;
      }
      .ant-table-tbody {
        > tr:not(.click-row-bgc):hover > td {
          background: #fafafa !important;
        }
      }
      .ant-table-tbody {
        > .click-row-bgc:hover > td {
          background: #fff4e6 !important;
        }
      }
      &__pagintion {
        display: flex;
        flex-direction: revert;
        justify-content: flex-end;
        padding-top: 14px;
      }
    }

    //修复质量折线图
    &__line_chart {
      background-color: #fff;
      // padding: 0 20px;
      padding: 20px;
      margin-bottom: 10px;
      &__title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      &__chart {
        min-height: 340px;
        margin-top: 10px;
      }
    }

    //个人得分数据
    &__personal_score_data {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      padding: 20px;
      margin-bottom: 10px;
      margin-top: 10px;

      &__title {
        font-size: 16px;

        font-weight: 500;
        color: #333333;
        margin-bottom: 14px;
      }
      &__table {
        > .ant-table-wrapper {
          .ant-table-thead > tr {
            background: url('./img/personal_score_data.png');
            background-size: 100%;

            > th {
              color: #ffffff;
              background: unset;
            }
          }
          .ant-table-tbody {
            > tr:last-child {
              > td {
                border-bottom: 1px solid #f0f0f0;
              }
            }
          }
        }
        .ant-table-thead {
          background-image: url('./img/table-thead-bgc.png');
          background-position: center;
          background-size: cover;
          height: 56px;
        }
        .ant-table-thead > tr > th {
          background: none;
          font-weight: 500;
          color: #ffffff;
          border-bottom: none;
        }
        tr > th,
        tr > td {
          padding-right: 0;
        }
        tr > th:last-child,
        tr > td:last-child {
          padding-right: 20px;
        }
        .ant-table-summary {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;

          tr > td:last-child {
            text-align: right;
          }
          tr > td {
            border-bottom: 0;
          }
        }
      }
    }

    //质量最佳Top5，缺陷修复Top5，编码得分排行，缺陷得分排行
    &__rank_list {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      padding: 20px;

      .list_item {
        width: 50%;
        // min-height: 408px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        padding: 20px 20px 10px 20px;
        margin-top: 10px;
        &:not(:nth-child(2n)) {
          width: calc(50% - 20px);
          margin-right: 10px;
        }
        // 去除表单最后一行横线
        .ant-table-tbody {
          > tr:last-child {
            > td {
              border-bottom: 0;
            }
          }
        }

        &__title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 14px;
        }

        &__item {
          .qualityImg {
            .ant-table-thead > tr {
              background: url('./img/best_quality_top5.png');
            }
          }
          .fixBugImg {
            .ant-table-thead > tr {
              background: url('./img/fix_bug_top5.png');
            }
          }
          .codeRankImg {
            .ant-table-thead > tr {
              background: url('./img/code_score_rank.png');
            }
          }
          .issueScoreRankImg {
            .ant-table-thead > tr {
              background: url('./img/issue_score_rank.png');
            }
          }
          > .ant-table-wrapper {
            .ant-table-thead > tr {
              background-size: 100%;

              > th {
                color: #ffffff;
                background: unset;
              }
            }
          }

          &__no_data {
            .ant-table-tbody {
              height: 300px;
            }
          }
        }
      }

      &__get_more {
        font-size: 14px;

        font-weight: 400;
        color: #ff6600;
        line-height: 20px;
        background: linear-gradient(135deg, #51b6ff 0%, #0396ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
    }
  }

  //项目排行榜
  .project_ranking {
    //项目质量最佳Top5
    //迭代质量最佳Top5
    &__best_quality_lists {
      &_box {
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        padding: 20px;
        margin-bottom: 10px;

        &_title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          margin-bottom: 14px;
        }

        &_item {
          > .ant-table-wrapper {
            .ant-table-thead > tr {
              background: url('./img/personal_score_data.png');
              background-size: 100%;

              > th {
                color: #ffffff;
                background: unset;
              }
            }
          }
        }
      }
    }

    //项目得分排行
    &__score_ranking {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      padding: 20px;

      &__top_part {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        align-items: center;

        &__title {
          height: 22px;
          font-size: 16px;

          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
        &__tabs_btn {
          label:first-child {
            border-radius: 2px 0 0 2px;
          }
          label:last-child {
            border-radius: 0 2px 2px 0;
          }
          > .ant-radio-group {
            > .ant-radio-button-wrapper {
              height: 28px;
              line-height: 26px;
              padding: 0 10px;
              width: 80px;
              text-align: center;
              &:not(.ant-radio-button-wrapper-checked) {
                color: #ff6600;
                border-color: #ff6600;
              }
            }
          }
        }
      }

      &__table {
        font-size: 14px;

        > .ant-table-wrapper {
          > .ant-spin-nested-loading {
            > .ant-spin-container {
              > .ant-table {
                > .ant-table-container {
                  > .ant-table-content {
                    .ant-table-thead {
                      background-image: url('./img/table-thead-bgc.png');
                      background-position: center;
                      background-size: cover;
                      height: 70px;
                    }
                    .ant-table-thead > tr > th {
                      background: none;
                      font-weight: 500;
                      color: #ffffff;
                      border-bottom: none;
                    }
                    > table > thead > tr > th {
                      padding: 0px 16px;
                    }
                  }
                }
              }
            }
            > tr:last-child {
              > th {
                padding-top: 5px;
                padding-bottom: 10px;
              }
            }

            > tr > th {
              color: #ffffff;
              background: unset;
              line-height: 20px;
            }
          }
        }
      }

      &__more {
        font-size: 15px;

        font-weight: 400;
        color: #ff6600;
        margin-top: 14px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .thousand_code-date {
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;

    &_picker {
      width: 208px;
    }
  }

  .thousand_code {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f0f0f0;

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      margin-left: 20px;
      height: 56px;
    }
    .ant-tabs-tab {
      font-size: 16px;
      padding: 18px 0;
      margin: 0 20px 0px 0px;
    }
    .ant-tabs .ant-tabs-nav {
      margin: 0;
    }
    .ant-tree .ant-tree-treenode {
      padding: 0px;
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: #fff3eb;
      color: #ff6600;
    }
    .ant-tree-switcher {
      line-height: 40px;
      width: 16px;
    }
    .ant-tree .ant-tree-node-content-wrapper {
      line-height: 40px;
      width: 340px;
    }

    &_TreeControl {
      width: 330px;
      height: 650px;
      border-right: 1px solid #e6e6e6;
      padding: 16px 0 20px 0;
      overflow-y: scroll;
    }

    &_TreeImg {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 40px;
      white-space: nowrap;
      img {
        font-size: 0;
        margin-right: 6px;
      }
    }
    &_CodeDetail {
      width: 810px;
      margin: 20px 0 20px 20px;
      &_statistical-range {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      &_input {
        width: 240px;
        border-radius: 4px;
        margin: 14px 0 14px 0;
      }
      &_button {
        // margin: 20px 0 0 8px;
        border-radius: 4px;
        margin-left: 10px;
      }
      &_cardList {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
      &_card {
        width: 100%;
        padding: 20px;
        background: linear-gradient(180deg, #fafafa 0%, #ffffff 100%);
        border-radius: 4px;
        border: 1px solid #ebedf0;
        margin-right: 10px;

        &_key {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          margin-bottom: 14px;
        }

        &_value {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      > .ant-table-wrapper {
        .ant-table-thead {
          background: url('./img/best_quality_top5.png');
          background-size: cover;

          > tr > th {
            color: #ffffff;
            background: unset;
            line-height: 20px;
          }
        }
      }
    }
  }
  // 实时人效
  .realtime_effect {
    .tips {
      padding: 20px;
      background: #ffffff;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      li {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
      }
      .ant-alert-info {
        background: #fff6f0;
        border-radius: 4px;
        border: 1px solid #ff6600;
        padding: 14px;
        font-size: 14px;
      }
    }
    .search_group {
      height: 72px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      padding: 20px;
      display: flex;
      &_button {
        width: 70px;
        border-radius: 4px;
      }
      &_item {
        width: 100%;
        margin-right: 10px;
        border-radius: 4px;
      }
    }
    .effect_table {
      margin: 10px 0;
      padding: 20px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      &_title {
        width: 100%;
        height: 56px;
        background-image: url('./img/effect_table_table.png');
        background-size: 100%;
        &_th {
          color: #fff;
          font-size: 14px;
        }
        &_th:first-child {
          padding-left: 16px;
        }
        &_th:last-child {
          // padding-right: 16px;
        }
      }
      &_pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 14px;
      }
      .ant-table-tbody > tr > td {
        padding-left: 20px;
        position: relative;
      }
      .ant-table-row-indent + .ant-table-row-expand-icon {
        position: absolute;
        left: 0px;
      }

      .ant-table-tbody {
          >tr {
              cursor: pointer;

              >td {
                  height: 56px;
                  padding-top: 0;
                  padding-bottom: 0;
              }
          }
      }
    }
  }
}
.particulars_drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-title {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
    .ant-drawer-body {
      padding-top: 0px;
      padding-bottom: 20px;
      .ant-tabs-nav {
        position: fixed;
        height: 75px;
        margin: 0;
        z-index: 299;
        width: 100%;
        background: #ffffff;
        .ant-tabs-nav-wrap {
          height: 66px;
          .ant-tabs-nav-list {
            height: 53px;
            width: calc(100% - 48px);
            border-bottom: 1px solid #ebedf0;
            .ant-tabs-tab {
              padding: 0;
            }
          }
        }
      }
      .ant-tabs-content-holder {
        padding-top: 75px;
      }
    }
    // 固定列最后一个样式
    .ant-table-cell-fix-left-last::after {
      box-shadow: inset 10px 0 8px -8px rgb(200 201 204 / 50%)
    }
    .ant-table-header {
      // 表头样式重置
      .ant-table-thead {
        th {
          background: #f0f2f5;
          border-right: 1px solid #ebedf0 !important;
          border-bottom: 1px solid #ebedf0 !important;
          padding: 0;
          color: #333333;
          font-weight: 500;
        }
        td {
          padding: 0;
        }
        // 固定列高度
        .ant-table-cell-fix-left {
          height: 90px;
        }
        // 周高度
        .week-cell {
          height: 38px;
        }
        .current-week-cell {
          background: #ffe5d3;
          color: #ff6600;
        }

        // 天高度
        .day-cell {
          height: 53px;
          padding: 0;
          .day {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
          }
          .week {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
          }
        }
        .current-day-cell {
          background: #ff6600;
          .day {
            color: #ffffff;
          }
          .week {
            color: #ffffff;
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        border: none;
      }
      td {
        padding: 13px 0px;
        border-right: 1px solid #ebedf0 !important;
        border-bottom: 1px solid #ebedf0;
      }
      // 周末
      .weekendColor {
        background: #f0f2f5 !important;
      }
      // 不符合工时
      .lackColor {
        background: rgba(245, 55, 49, 0.1) !important;
        color: #f53731;
      }
      // 无投入
      .noPutColor {
        background: #dfdfdf !important;
      }
    }
    .ant-table-summary {
      td {
        padding: 13px 0px;
        border-right: 1px solid #ebedf0 !important;
        border-bottom: 1px solid #ebedf0;
      }
      .gray-color {
        background: #f0f2f5;
      }
    }
    // 计划投入
    .investment_total {
      .ant-table-thead {
        .ant-table-cell-fix-left:nth-of-type(1) {
          border-right: none !important;
        }
        .ant-table-cell-fix-left:nth-of-type(2) {
          border-right: none !important;
        }
        .ant-table-cell-fix-left:nth-of-type(3) {
          border-right: none !important;
        }
        .ant-table-cell-fix-left:nth-of-type(4) {
          border-right: none !important;
        }
      }
      // 处理固定列的边框
      .ant-table-tbody {
        tr {
          td:nth-of-type(1) {
            border-right: none !important;
          }
          td:nth-of-type(2) {
            border-right: none !important;
          }
          td:nth-of-type(3) {
            border-right: none !important;
          }
          td:nth-of-type(4) {
            border-right: none !important;
          }
        }
      }
    }

    // 里程碑
    .investment_phase {
      .ant-table-thead {
        .ant-table-cell-fix-left:nth-of-type(1) {
          border-right: none !important;
        }
        .ant-table-cell-fix-left:nth-of-type(2) {
          border-right: none !important;
        }
      }
      // 处理固定列的边框
      .ant-table-tbody {
        tr {
          td:nth-of-type(1) {
            border-left: none;
            border-right: none !important;
          }
          td:nth-of-type(2) {
            border-left: none;
          }
        }
        tr:nth-of-type(2) {
          td:nth-of-type(1) {
            border-left: none;
            border-right: 1px solid #ebedf0 !important;
          }
          td:nth-of-type(2) {
            border-left: none;
            border-right: none !important;
          }
          td:nth-of-type(3) {
            border-left: none;
          }
        }
      }
    }

    //合计以及投入表格
    .combinedColor {
      background: #eef6ff !important;
    }
    .weight {
      font-weight: 500;
      color: #000000;
    }

    // 上下表格间距
    .perch {
      height: 11px;
    }
  }
}
//日期选择弹窗 选中样式
.date_picker_month_dropdown {
  .ant-picker-cell-selected {
    color: #ff6600;

    &::before {
      border: 1px solid #ff6600;
    }
  }
}
//搜索项日期选择相关样式更改
.ant-picker-cell .ant-picker-cell-inner {
  color: #323233 !important;
}
// 选中样式
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #fff !important;
  border: 1px solid #ff6600 !important;
  color: #ff6600 !important;
}
// 日期选择弹窗 鼠标移入样式
.ant-picker-cell .ant-picker-cell-inner {
  /* autoprefixer: off*/
  &:hover {
    /* autoprefixer: ignore next */
    background: #fff3eb !important;
  }
  /*! autoprefixer: on */
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fff3eb !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

@primary-color: #ff6600;