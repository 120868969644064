.project_list_milestone_list {
    border-top: 1px solid #EBEDF0;
    &__title {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--text {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
        }

        &--checkbox {}
    }

    &__content {
        display: flex;
        justify-content: space-between;
        &--left {
            width: 124px;
            border-right: 1px solid #EBEDF0;
            >li {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0,0,0,0.8);
                line-height: 40px;
                border-right: 2px solid transparent;
                cursor: pointer;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                >span {
                    display: inline-block;
                    min-width: 20px;
                    margin-right: 4px;
                }
            }

            &__active {
                color: #FF6600 !important;
                border-right-color: #FF6600 !important;
            }
        }

        &--right {
            width: calc(100% - 124px - 10px);
            &__list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px 14px;

                >li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;

                    display: flex;
                    align-items: center;
                    >span {
                        color: #666;
                    }
                    >div {
                        color: #333;
                    }
                }
            }
            &__table {
                .ant-table-thead {
                    >tr {
                        >th {
                            height: 54px;
                            padding: 10px 5px;
                            background: #F0F2F5;
                            font-size: 14px;
                            font-weight: 500;
                            color: #333333;
                            line-height: 20px;
                            &::before {
                                display: none;
                            }
                            &:first-child {
                                padding-left: 10px;
                            }
                            &:last-child {
                                padding: 0;
                            }
                        }
                    }
                }

                .ant-table-tbody {
                    >tr {
                        &:not(:first-child) {
                            >td {
                                height: 41px;
                                padding: 0 5px;
                                font-size: 14px;
                                font-weight: 400;
                                color: rgba(0,0,0,0.8);
                                line-height: 20px;
                                &:first-child {
                                    padding-left: 10px;
                                }
                                &:last-child {
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }

                .ant-table-cell-fix-left-first, .ant-table-cell-fix-left-last {
                    &::after {
                        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
                    }
                }

                &--column {
                    display: flex;
                    align-items: center;
                    >img {
                        margin-right: 4px;
                    }
                    >span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0,0,0,0.8);
                        line-height: 20px;
                    }
                }

                &--columnGroup {
                    display: flex;
                    align-items: center;
                    >ul {
                        width: 40px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        >li {
                            font-size: 12px;
                            font-weight: 400;
                            color: #333333;
                            line-height: 18px;
                        }
                    }

                    &__active {
                        background: #FF6600;
                        >li {
                            color: #fff !important;
                        }
                    }
                }

                &--list {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    display: flex;
                    align-items: center;

                    >ul {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        >li {
                            width: 40px;
                            height: 100%;
                        }
                    }

                    &__progress {
                        position: absolute;
                    }
                }
            }
        }
    }
}
@primary-color: #ff6600;